import React from 'react';
import Modal from 'react-modal';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { FM_DOMAIN_NAME, FM_FILENAME_QUALITY, FM_CHECKLIST_SHORTCUT, FM_ACCOUNT_NAME, FM_ACCOUNT_PASSWORD } from "../../config.js"; //CHANGED UPDATE 2023/07/04 #25

Modal.setAppElement("#root");

const customStyles = {
  overlay: {
    zIndex: "100",
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  content: {
    width: "70%",
    maxWidth: "460px",
    height: "auto",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0",
    transform: "translate(-50%, -50%)"
  }
};

const ClickEventModal = (props) => {
  const { modalIsOpen, schedulerData, modalParam, linestatus, refStatus, refText, handleClickEventCloseModal, handleSelectedDateUpdateButtonClicked, handleBikouUpdateButtonClicked, handleSyukkaUpdateButtonClicked } = props;
  const { UUID, 製品番号, 立米, 配合, ステータス, 備考, 備考フラグ, 出荷日テキスト, 出荷日テキストフラグ, 日付, resourceId } = modalParam;

  var options = linestatus.map(
    (item) => (
      <option key={item.status} value={item.status}>
        {item.status}
      </option>
    )
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleClickEventCloseModal}
      // afterOpenModal={afterOpenModal}
      contentLabel="Modal"
      // closeTimeoutMS={100}
      style={customStyles}
    >
      {modalParam && 製品番号 &&
        <Container className='container__product'>{/* CHANGED UPDATE 2023/07/12 #28 */}
          <Row>
            {/* CHANGED:ADD 2023/07/04 #25 */}
            <div className='fm-link__checklist'>
            <a href={`fmp://${FM_ACCOUNT_NAME}:${FM_ACCOUNT_PASSWORD}@${FM_DOMAIN_NAME}/${FM_FILENAME_QUALITY}?script=${FM_CHECKLIST_SHORTCUT.scriptName}&param=${UUID}`}>
                <i /><span>チェックリストを開く</span></a>
            </div>
          </Row>
          <Row>
            <Col xs={4}>製品番号</Col>
            <Col xs={8}>{製品番号}</Col>
          </Row>
          <Row>
            <Col xs={4}>㎥</Col>
            <Col xs={8}>{立米}</Col>
          </Row>
          <Row>
            <Col xs={4}>配合</Col>
            <Col xs={8}>{配合}</Col>
          </Row>
          <Row>
            <Col xs={4}>ステータス</Col>
            <Col xs={8}>
              <select defaultValue={ステータス} ref={refStatus}>
                {options}
              </select>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>備考</Col>
            <Col xs={8}><input type="text" defaultValue={備考} ref={refText} /></Col>
          </Row>
          <Row className='btn_area'>
            <div className="mdl_btn mdl_click">
              <div className="mdl_btn_update">
                <Button onClick={() => { handleSelectedDateUpdateButtonClicked(schedulerData, UUID); }}>更新</Button>
              </div>
              <div className="mdl_btn_cancel">
                <Button onClick={handleClickEventCloseModal}>キャンセル</Button>
              </div>
            </div>
          </Row>
        </Container>
      }
      {modalParam && !製品番号 && 備考フラグ &&
        <Container className='text_only'>
          <Row>
            <Col xs={4}>備考</Col>
            <Col xs={8}><input type="text" defaultValue={備考} ref={refText} /></Col>
          </Row>
          <Row className='btn_area'>
            <div className="mdl_btn mdl_click">
              <div className="mdl_btn_update">
                <Button onClick={() => { handleBikouUpdateButtonClicked(schedulerData, UUID, resourceId, 日付); }}>更新</Button>
              </div>
              <div className="mdl_btn_cancel">
                <Button onClick={handleClickEventCloseModal}>キャンセル</Button>
              </div>
            </div>
          </Row>
        </Container>
      }
      {modalParam && !製品番号 && 出荷日テキストフラグ &&
        <Container className='text_only'>
          <Row>
            <Col xs={4}>出荷</Col>
            <Col xs={8}><input type="text" defaultValue={出荷日テキスト} ref={refText} /></Col>
          </Row>
          <Row className='btn_area'>
            <div className="mdl_btn mdl_click">
              <div className="mdl_btn_update">
                <Button onClick={() => { handleSyukkaUpdateButtonClicked(schedulerData, UUID, resourceId, 日付); }}>更新</Button>
              </div>
              <div className="mdl_btn_cancel">
                <Button onClick={handleClickEventCloseModal}>キャンセル</Button>
              </div>
            </div>
          </Row>
        </Container>
      }
    </Modal>
  )
}

export default ClickEventModal; 