//---------------------------------------
// 日付フォーマット
// 引数
//    date   [in] 日付でフォーマットされた値
//    format [in] フォーマットしたい形式(例　YYYY年MM月DD日)
// 戻値
//    format  : フォーマットされた日付の文字列
//---------------------------------------
export function formatDate(date, format) {
  if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
  format = format.replace(/YYYY/g, date.getFullYear());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  if (format.match(/S/g)) {
    var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
    var length = format.match(/S/g).length;
    for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
  }
  return format;
}

export function hankaku2Zenkaku(str) {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}

export function toBoolean(data) {
  return data.toString().toLowerCase() === 'true';
}

export function getDayOfWeek(dateStr) {
  var date = new Date(dateStr);
  var dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];

  return dayOfWeekStr;
}

//CHANGED ADD 2023/07/04 #17
export function blackOrWhite(hexcolor) {
  let r = parseInt(hexcolor.substring(1, 3), 16);
  let g = parseInt(hexcolor.substring(3, 5), 16);
  let b = parseInt(hexcolor.substring(5, 7), 16);

  return ((((r * 299) + (g * 587) + (b * 114)) / 1000) < 128) ? "white" : "black";
}
