import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Auth from './Auth';
import Login from './Login/Login';
import Logout from './Logout/Logout';
import Scheduler from './Scheduler/Scheduler';

class App extends Component {
  render() {
    return (
      <div className="warapper">
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />

          <Auth>
            <Route exact path="/schedule" component={Scheduler} />
            <Redirect from="/" to="/schedule" />
          </Auth>
        </Switch>
      </div>
    );
  }
}

export default App;