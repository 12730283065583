import React, { Component } from "react";
import Select from 'react-select'
import { Link } from 'react-router-dom';
import { Radio, Calendar } from 'antd'
import { FormLabel, FormGroup, FormControlLabel, Checkbox, Grid, Popover } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import User from './../../User';

export default class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calenderOpen: false,
      menuOpen: false,
      anchorEl: null
    }
  }

  printClick = () => {
    window.print();
  };

  handleCalenderOpen = (e) => {
    e.preventDefault();
    this.setState({
      calenderOpen: !this.state.calenderOpen,
      anchorEl: e.currentTarget,
    });
  }

  handleCalenderClose = () => {
    this.setState({
      calenderOpen: false,
    });
  }

  handleMenuOpen = (e) => {
    e.preventDefault();
    this.setState({
      menuOpen: !this.state.menuOpen,
      anchorEl: e.currentTarget,
    });
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    });
  }

  onViewChange = (e) => {
    const { handleViewChange, schedulerData } = this.props;
    let viewType = parseInt(e.target.value.charAt(0));
    let showAgenda = e.target.value.charAt(1) === '1';
    let isEventPerspective = e.target.value.charAt(2) === '1';
    handleViewChange(schedulerData, { viewType: viewType, showAgenda: showAgenda, isEventPerspective: isEventPerspective });
  }

  onSortChange = (e) => {
    const { handleSortChange, schedulerData } = this.props;
    handleSortChange(e.target.value, schedulerData);
  }

  goNext = (e) => {
    const { handleNextClick, schedulerData } = this.props;
    handleNextClick(schedulerData);
    e.currentTarget.blur();
  }

  goBack = (e) => {
    const { handlePrevClick, schedulerData } = this.props;
    handlePrevClick(schedulerData);
    e.currentTarget.blur();
  }

  onSelect = (date) => {
    this.setState({
      visible: false,
      calenderOpen: false,
    });

    const { handleSelectDate, schedulerData } = this.props;
    handleSelectDate(schedulerData, date);
  }

  onCheck = (item) => {
    const { handleCheckedChanged, checkedValues } = this.props;
    checkedValues.filter(v => v.status === item.status).length > 0
      ? checkedValues.splice(checkedValues.findIndex(v => v.status === item.status), 1)
      : checkedValues.push(item);
    handleCheckedChanged(checkedValues);
  }

  onZoomIn = () => {
    const { handleScaleChange, scale } = this.props;
    handleScaleChange(scale + 0.17);
  }

  onZoomOut = () => {
    const { handleScaleChange, scale } = this.props;
    handleScaleChange(scale - 0.17);
  }

  printClick = () => {
    window.print();
  }

  render() {
    const { schedulerData, linestatus, lines, articles, selectedSort, selectedLine, selectedArticle, checkedValues, handleLineChange, handleArticleChange } = this.props;
    const { viewType, showAgenda, isEventPerspective, config } = schedulerData;

    const dropdown1 = selectedSort === "ライン別" ?
      <Select isClearable={true} isSearchable={false} options={lines} value={selectedLine} onChange={(value) => { handleLineChange(value, schedulerData, selectedSort, selectedArticle); }} placeholder="ラインを選択" /> :
      <Select isClearable={true} isSearchable={false} options={articles} value={selectedArticle} onChange={(value) => { handleArticleChange(value, schedulerData, selectedSort, selectedLine); }} placeholder="物件を選択" />;

    const dropdown2 = selectedSort === "ライン別" ?
      <Select isClearable={true} isSearchable={false} options={articles} value={selectedArticle} onChange={(value) => { handleArticleChange(value, schedulerData, selectedSort, selectedLine); }} placeholder="物件を選択" /> :
      <Select isClearable={true} isSearchable={false} options={lines} value={selectedLine} onChange={(value) => { handleLineChange(value, schedulerData, selectedSort, selectedArticle); }} placeholder="ラインを選択" />;

    const dateLabel = schedulerData.getDateLabel();
    const defaultValue = `${viewType}${showAgenda ? 1 : 0}${isEventPerspective ? 1 : 0}`;
    const popover = <div className="popover-calendar"><Calendar fullscreen={false} onSelect={this.onSelect} /></div>;
    const radioButtonList = config.views.map(
      (item) => (
        <Radio.Button key={`${item.viewType}${item.showAgenda ? 1 : 0}${item.isEventPerspective ? 1 : 0}`}
          value={`${item.viewType}${item.showAgenda ? 1 : 0}${item.isEventPerspective ? 1 : 0}`}>
          <span style={{ margin: "0px 8px" }}>
            {item.viewName}
          </span>
        </Radio.Button>
      )
    );

    var options = linestatus.map(
      (item) => (
        <FormControlLabel key={item.code}
          control={< Checkbox onChange={() => { this.onCheck(item); }} checked={checkedValues.filter(e => e.status === item.status).length > 0} value={item} size="small" color="primary" />}
          label={<span className={'menu-checkbox'}>{item.status}</span>}
        />
      )
    );

    return (
      <nav className="navbar navbar-dark bg-primary fixed-top">
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
          <Grid item xs={2}>
            <ChevronLeftIcon fontSize="default" style={{ color: grey[50] }} className="icon-nav" onClick={this.goBack} />
            <span className={'text-white'} style={{ cursor: 'pointer' }} onClick={this.handleCalenderOpen} >{dateLabel}</span>
            <Popover
              open={this.state.calenderOpen}
              anchorEl={this.state.anchorEl}
              onClose={this.handleCalenderClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {popover}
            </Popover>
            <ChevronRightIcon fontSize="default" style={{ color: grey[50] }} className="icon-zoomout" onClick={this.goNext} />
          </Grid>
          <Grid item xs={2}>
            <Radio.Group defaultValue={defaultValue} buttonStyle="solid" onChange={this.onViewChange}>
              {radioButtonList}
            </Radio.Group>
          </Grid>
          <Grid item xs={2}>
            <Radio.Group defaultValue={selectedSort} buttonStyle="solid" onChange={this.onSortChange}>
              <Radio.Button value={"ライン別"}><span style={{ margin: "0px 1px" }}>ライン別</span></Radio.Button>
              <Radio.Button value={"物件別"}><span style={{ margin: "0px 8px" }}>物件別</span>
              </Radio.Button>
            </Radio.Group>
          </Grid>
          <Grid item xs={2}>
            {dropdown1}
          </Grid>
          <Grid item xs={2}>
            {dropdown2}
          </Grid>
          <Grid item xs={1}>
            <ZoomInIcon fontSize="default" style={{ color: grey[50] }} className="icon-zoomin" onClick={this.onZoomIn} />
            <ZoomOutIcon fontSize="default" style={{ color: grey[50] }} className="icon-zoomout" onClick={this.onZoomOut} />
            <MenuIcon fontSize="default" style={{ color: grey[50] }} className="icon-menu" onClick={this.handleMenuOpen} />
            <Popover
              open={this.state.menuOpen}
              anchorEl={this.state.anchorEl}
              onClose={this.handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <FormLabel>色つけ対象選択</FormLabel>
              <FormGroup>
                {options}
              </FormGroup>
            </Popover>
          </Grid>
          <Grid item xs={1}>
            <span className="text-white">{User.getUserName()}</span>
            <br></br>
            <Link className="navbar-logout" to="/logout">ログアウト</Link>
          </Grid>
        </Grid>
      </nav>
    );
  }
}