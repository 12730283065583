import React, { Component } from 'react';
import { Form, Button, Container, Row, Alert } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import User from '../User';
import './Login.css';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usercode: '',
      password: '',
      errMessage: '',
    };
  }

  click = async () => {
    try {
      const isLoggedIn = await User.login(this.state.usercode, this.state.password);
      if (isLoggedIn === true) {
        this.props.history.push({ pathname: 'schedule' });
      } else {
        this.setState({ errMessage: '従業員コードかパスワードが違います' });
      }
    } catch (e) {
      this.setState({ errMessage: 'ログインできませんでした' });
    }
  };

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  keyDown = e => {
    var ENTER = 13;
    if (e.keyCode === ENTER) {
      this.click();
    }
  };

  render() {
    return (
      <Container className="center">
        <Row className="justify-content-md-center">
          <Form>
            {this.state.errMessage && (
              <Alert variant="danger">{this.state.errMessage}</Alert>
            )}
            <p>
              <b>ログイン</b>
            </p>
            <Form.Group controlId="usercode">
              <Form.Label>従業員コード</Form.Label>
              <Form.Control
                type="usercode"
                placeholder="従業員コードを入力"
                onChange={this.handleChange}
                onKeyDown={this.keyDown}
                value={this.state.usercode}
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>パスワード</Form.Label>
              <Form.Control
                type="password"
                placeholder="パスワードを入力"
                onChange={this.handleChange}
                onKeyDown={this.keyDown}
                value={this.state.password}
              />
            </Form.Group>
            <Button variant="primary" type="button" onClick={this.click}>
              ログイン
            </Button>
          </Form>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Login);
