import React from 'react';
import Modal from 'react-modal';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { getDayOfWeek } from '../../Function/Function';

Modal.setAppElement("#root");

const customStyles = {
  overlay: {
    zIndex: "100",
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  content: {
    width: "70%",
    maxWidth: "460px",
    height: "360px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0",
    transform: "translate(-50%, -50%)"
  }
};

const MoveEventModal = (props) => {
  const { modalIsOpen, schedulerData, modalParam, handleMoveEventCloseModal, handleSelectedDateMoveButtonClicked, handleSelectedDateAndLaterMoveButtonClicked, handleKatawakuMoveButtonClicked } = props;
  const { UUID, 製品番号, 変更先リソースID, 変更元型枠番号, 変更先型枠番号, 変更元予定日, 変更先予定日, 最終日, 変更元製造ラインコード, 変更先製造ラインコード, 変更元製造ライン名称, 変更先製造ライン名称, 型枠調整フラグ, 製造ライン調整フラグ } = modalParam;
  const beforeDate = 変更元予定日 + '(' + getDayOfWeek(変更元予定日) + ')';
  const afterDate = 変更先予定日 + '(' + getDayOfWeek(変更先予定日) + ')';

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleMoveEventCloseModal}
      // afterOpenModal={afterOpenModal}
      contentLabel="Modal"
      // closeTimeoutMS={100}
      style={customStyles}
    >
      {modalParam && !型枠調整フラグ && !製造ライン調整フラグ &&
        <Container className='move_content'>
          <Row>
            <Col xs={3}>製品番号</Col>
            <Col xs={9}>{製品番号}</Col>
          </Row>
          <Row>
            <Col xs={3}>型枠番号</Col>
            <Col xs={9}>{変更元型枠番号}</Col>
          </Row>
          <Row>
            <Col xs={3}>日付</Col>
            <Col xs={4}>{beforeDate}</Col>
            <Col xs={1}>→</Col>
            <Col xs={4}>{afterDate}</Col>
          </Row>
          <Row className='btn_area'>
            <div className="mdl_btn mdl_move_date">
              <div className="mdl_btn_update">
                <Button onClick={() => { handleSelectedDateMoveButtonClicked(schedulerData, UUID, 変更先予定日, 変更元製造ラインコード); }}>選択日のみ調整</Button>
                <Button onClick={() => { handleSelectedDateAndLaterMoveButtonClicked(schedulerData, UUID, 変更元予定日, 変更先予定日, 最終日, 変更元製造ラインコード); }}>選択日および以降の調整</Button>
              </div>
              <div className="mdl_btn_cancel">
                <Button onClick={handleMoveEventCloseModal}>キャンセル</Button>
              </div>
            </div>
          </Row>
        </Container>
      }
      {/* CHANGED:ADD 2023/7/12 #20 */}
      {modalParam && !型枠調整フラグ && 製造ライン調整フラグ &&
        <Container>
          <Row>
            <Col xs={3}>製品番号</Col>
            <Col xs={9}>{製品番号}</Col>
          </Row>
          <Row>
            <Col xs={3}>ライン</Col>
            <Col xs={4}>{変更元製造ライン名称}</Col>
            <Col xs={1}>→</Col>
            <Col xs={4}>{変更先製造ライン名称}</Col>
          </Row>
          <Row>
            <Col xs={3}>日付</Col>
            <Col xs={4}>{beforeDate}</Col>
            <Col xs={1}>→</Col>
            <Col xs={4}>{afterDate}</Col>
          </Row>
          <Row className='btn_area'>
            <div className="mdl_btn mdl_move_kata">
              <div className="mdl_btn_update">
                <Button onClick={() => { handleKatawakuMoveButtonClicked(schedulerData, UUID, 変更先リソースID, 変更先型枠番号, 変更元予定日, 変更先予定日, 変更元製造ラインコード, 変更先製造ラインコード); }}>製造ラインの調整</Button>
              </div>
              <div className="mdl_btn_cancel">
                <Button onClick={handleMoveEventCloseModal}>キャンセル</Button>
              </div>
            </div>
          </Row>
        </Container>
      }
      {modalParam && 型枠調整フラグ && !製造ライン調整フラグ &&
        <Container>
          <Row>
            <Col xs={3}>製品番号</Col>
            <Col xs={9}>{製品番号}</Col>
          </Row>
          <Row>
            <Col xs={3}>型枠番号</Col>
            <Col xs={4}>{変更元型枠番号}</Col>
            <Col xs={1}>→</Col>
            <Col xs={4}>{変更先型枠番号}</Col>
          </Row>
          <Row>
            <Col xs={3}>日付</Col>
            <Col xs={4}>{beforeDate}</Col>
            <Col xs={1}>→</Col>
            <Col xs={4}>{afterDate}</Col>
          </Row>
          <Row className='btn_area'>
            <div className="mdl_btn mdl_move_kata">
              <div className="mdl_btn_update">
                <Button onClick={() => { handleKatawakuMoveButtonClicked(schedulerData, UUID, 変更先リソースID, 変更先型枠番号, 変更元予定日, 変更先予定日, 変更元製造ラインコード, 変更先製造ラインコード); }}>型枠の調整</Button>
              </div>
              <div className="mdl_btn_cancel">
                <Button onClick={handleMoveEventCloseModal}>キャンセル</Button>
              </div>
            </div>
          </Row>
        </Container>
      }
    </Modal>
  )
}

export default MoveEventModal; 