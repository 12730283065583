import axios from 'axios';
import { API_URL } from "./config.js";

class User {
  isLoggedIn = () => this.get('isLoggedIn') === 'true';
  getUserId = () => this.get('userId');
  getUserName = () => this.get('userName');

  set = (key, value) => sessionStorage.setItem(key, value);

  get = key => this.getSessionStorage(key);

  getSessionStorage = key => {
    const ret = sessionStorage.getItem(key);
    if (ret) {
      return ret;
    }
    return null;
  };

  // ログイン処理
  login = async (usercode, password) => {
    const layout = 'D0001_mst従業員';

    const field = {
      'コード': '==' + usercode,
      'パスワード': '==' + password,
      '削除済みフラグ': 0
    }
    
    const json = {
      'layout': layout,
      'data':{
        'query': [ field ],
        'limit': 1
      }
    };

    const result = (await axios.post(API_URL + '/filemaker', json)).data;
    const code = parseInt(result.messages[0].code);

    if (code !== 0) {
      return false;
    }

    const user = result.response.data[0].fieldData;

    this.set('isLoggedIn', true);
    this.set('userId', user.UUID);
    this.set('userName', user.氏名);
    return true;
  };

  // ログアウト処理
  logout = async () => {
    if (this.isLoggedIn()) {
      this.set('isLoggedIn', false);
      this.set('userId', null);
      this.set('userName', null);
    }
  };
}

export default new User();
